export const meta: BlockMeta = {
  editMode: "both",
  title: "Fill the gaps",
  description: "",
  keywords: ["fill the gaps"],
  // @ts-ignore
  icon: "admin-customizer",
  cache: false,
  tags: ["root"],
}

import { defineBlock, EditableText } from "eddev/blocks"
import { GridLines } from "@components/atoms/GridLines"

export default defineBlock("content/fill-the-gaps", (props) => {
  const filled = [12, 13, 16, 17, 20, 21]
  const filledMobile = [4, 6, 11]

  return (
    <div className={`subtheme-${props.scheme} relative`}>
      {props.gridlines && <GridLines />}
      <div className={`w-full flex bg-bg h-[320px] md:h-[480px] ${props.gridlines ? `border-b border-ruler` : ``}`}>
        <div className="grid-auto relative items-center pb-8 lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 flex-wrap items-center justify-center hidden md:flex">
            <EditableText
              className={`type-title-huge text-center w-full absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${env.admin ? `text-text` : `pointer-events-none text-outline`}`}
              store="text"
              placeholder="Enter text..."
            />
            {[...Array(36)].map((_, i) => (
              <div
                className={`h-[80px] w-1/4 md:w-1/6 ${filled.includes(i) ? `hover:bg-stroke` : `bg-stroke opacity-0 hover:opacity-100`} transition ease-in duration-500 delay-500 hover:delay-0 hover:duration-200 overflow-hidden`}
                key={i}
                style={{
                  clipPath: "inset(0)",
                }}
              >
                <EditableText
                  className="type-title-huge text-text text-center w-full absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  store="text"
                  placeholder="Enter text..."
                />
              </div>
            ))}
          </div>

          <div className="col-span-12 flex-wrap items-center justify-center md:hidden flex">
            <EditableText
              className={`type-title-huge text-center w-full absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${env.admin ? `text-text` : `pointer-events-none text-outline`}`}
              store="text"
              placeholder="Enter text..."
            />
            {[...Array(16)].map((_, i) => (
              <div
                className={`h-[80px] w-1/4 md:w-1/6 ${filledMobile.includes(i) ? `hover:bg-stroke` : `bg-stroke opacity-0 hover:opacity-100`} transition ease-in duration-500 delay-500 hover:delay-0 hover:duration-200 overflow-hidden`}
                key={i}
                style={{
                  clipPath: "inset(0)",
                }}
              >
                <EditableText
                  className="type-title-huge text-text text-center w-full absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  store="text"
                  placeholder="Enter text..."
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})
